<template>
	<div v-if="show" class="StyledErrorBanner-sc fsValidationError" role="alert">
		<div
			class="StyledErrorBannerText-sc FieldInlineError__StyledErrorBannerText-sc">
			<div class="StyledErrorBannerIcon-sc"></div>
			<div>{{errMsg}}</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			errMsg: {
				type: String,
				default: 'Required field'
			},
			show: {
				type: Boolean,
				default: false
			}
		}
	}
</script>

<style>
</style>