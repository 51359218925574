<template>
	<article>
		<section class="contact-banner">
			<div class="contact-banner__container">
				<div class="contact-banner__content">
					<h1 class="contact-banner__heading">Contact Us</h1>
				</div>
				<img src="/img/contact-banner__icon.svg" alt="" style="margin-left:auto;width:20%;">
			</div>
		</section>
		<section v-if="submitted">
			<div class="fsBody body-hosted-form">
				<form class="fsForm fsMaxCol1" action="#">
					<div class="fsPage">
						<table class="fsSection fsTable" cellspacing="0" cellpadding="0" role="presentation">
							<tbody class="fsSectionHeader">
								<tr valign="top" class="fsRowTop">
									<td class="fsRowOpen"></td>
									<td class="fsRowBody"></td>
									<td class="fsRowClose"></td>
								</tr>
								<tr valign="top" class="fsRow fsSectionRow">
									<td class="fsRowOpen"></td>
									<td class="fsRowBody fsCell fsSectionCell">

										<div id="fsSubmissionCheckmark">
											&nbsp;
										</div>
										<div class="fsSectionText" style="padding-left:78px" role="alert"
											aria-live="assertive" aria-atomic="true">
											<p>Your message was submitted successfully. Thank you for contacting
												Thinkninnovation!</p>
										</div>

									</td>
									<td class="fsRowClose"></td>
								</tr>
								<tr valign="top" class="fsRowBottom">
									<td class="fsRowOpen"></td>
									<td class="fsRowBody"></td>
									<td class="fsRowClose"></td>
								</tr>
							</tbody>
						</table>
					</div>

				</form>
			</div>
		</section>
		<section class="contact" v-else>
			<div class="contact__container">
				<div class="contact__form">
					<a class="contact__anchor" id="contactForm"></a>
					<h2 class="contact__heading">Get in touch</h2>
					<div class="fsform-container">
						<div class="fsBody fsBody_">
							<div class="InnerFormBody-sc" style="padding: calc(20px);">
								<form autocomplete="off" id="fsForm3766788" class="StyledForm-sc fsForm fsForm_"
									enctype="multipart/form-data" novalidate @submit.prevent="onSubmit">
									<div class="StyledPage-sc fsPage">
										<div id="fsSection0" class="StyledSection-sc fsSection"
											style="display: grid; column-gap: calc(20px); grid-template-columns: repeat(1, minmax(0px, 1fr));">
											<label class="StyledLabel-sc fsLabelVertical fsFieldCell"
												id="label-field88509634" for="field88509634"
												style="display: flex; visibility: visible; grid-column: span 1;">
												<div class="StyledLabelText-sc fsLabel fsRequiredLabel">
													<span>Type of inquiry</span><span
														class="StyledRequiredMarker-sc fsRequiredMarker">*</span>
												</div>
												<form-field-error :show="show.type_of_inquiry_error"></form-field-error>
												<select size="1" id="field88509634" type="select"
													label="Type of inquiry" required=""
													class="StyledSelectInput-sc fsFieldSelect" @change="validateTypeOfInquiryField"
													name="field88509634" v-model="form.type_of_inquiry">
													<option value=""></option>
													<option value="Partnerships">Partnerships</option>
													<option value="Privacy Inquiries">Privacy Inquiries</option>
													<option value="Legal Communications">Legal Communications</option>
													<option value="Press">Press</option>
												</select>
											</label>
											<label class="StyledLabel-sc fsLabelVertical fsFieldCell"
												id="label-field88548838" for="field88548838"
												style="display: flex; visibility: visible; grid-column: span 1;">
												<div class="StyledLabelText-sc fsLabel fsRequiredLabel">
													<span>Name</span><span
														class="StyledRequiredMarker-sc fsRequiredMarker">*</span>
												</div>
												<form-field-error :show="show.name_error"></form-field-error>
												<div class="StyledSubFieldGroup-sc fsSubFieldGroup">
													<div class="StyledSubField-sc">
														<input autocomplete="on"
															class="StyledInput-sc fsFieldShortAnswer" id="field88548838"
															type="text" label="Name" required="" name="field88548838"
															v-model="form.name"
															@input="validateName">
													</div>
												</div>
											</label>
											<label class="StyledLabel-sc fsLabelVertical fsFieldCell"
												id="label-field88509828" for="field88509828"
												data-testid="field-88509828"
												style="display: flex; visibility: visible; grid-column: span 1;"><a
													id="field-anchor-88509828" tabindex="-1" aria-hidden="true"></a>
												<div class="StyledLabelText-sc fsLabel fsRequiredLabel">
													<span>Email</span><span
														class="StyledRequiredMarker fsRequiredMarker">*</span>
												</div>
												<form-field-error :show="show.email_error" :errMsg="show.email_error_msg"></form-field-error>
												<div class="StyledSubFieldGroup-sc fsSubFieldGroup">
													<div class="StyledSubField-sc">
														<input id="field88509828"
															type="email" maxlength="" label="Email" required=""
															autocomplete="email" class="StyledInput-sc fsFieldEmail"
															name="field88509828" size="50" placeholder="" v-model="form.email"
															@input="validateEmail">
													</div>
												</div>
											</label>
											<label class="StyledLabel-sc fsLabelVertical fsFieldCell"
												id="label-field88509833" for="field88509833"
												style="display: flex; visibility: visible; grid-column: span 1;"><a
													id="field-anchor-88509833" tabindex="-1" aria-hidden="true"></a>
												<div class="StyledLabelText-sc fsLabel fsRequiredLabel">
													<span>Message</span><span
														class="StyledRequiredMarker-sc fsRequiredMarker">*</span>
												</div>
												<form-field-error :show="show.msg_error"></form-field-error>
												<textarea class="StyledInput-sc fsFieldLongAnswer" id="field88509833"
													type="text" maxlength="5000" label="Message" required=""
													name="field88509833" cols="50" rows="10" placeholder=""
													data-fs-field-id="88509833" data-fs-field-name="textarea"
													data-fs-field-alias="*" v-model="form.msg" @input="validateMsg"></textarea>
												<div aria-live="polite" aria-atomic="true" tabindex="-1"
													class="StyledLongAnswerFieldCounter-sc"><span
														style="position: absolute; left: -999em; width: 0.1em; height: 0.1em; overflow: hidden;">Characters
														remaining:</span>{{form.msg.length}}/500</div>
											</label>
										</div>
									</div>
									<div id="fsSubmit3766788" class="StyledFormFooter-sc fsSubmit fsPagination">
										<div class="StyledFormNavigations-sc"><button id="fsSubmitButton3766788"
												class="StyledSubmitButton-sc fsSubmitButton" type="submit">Get in
												Touch</button></div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</article>
</template>

<script>
	import FormFieldError from '@/components/contact/form-field-error'
	export default {
		components: {
			FormFieldError
		},
		data() {
			return {
				submitted: false,
				show: {
					type_of_inquiry_error: false,
					name_error: false,
					email_error: false,
					email_error_msg : '',
					msg_error: false
				},
				form: {
					type_of_inquiry: '',
					name: '',
					email: '',
					msg: ''
				}
			}
		},
		methods: {
			// 表单验证方法
			validateTypeOfInquiryField() {
				if (!this.form.type_of_inquiry) {
					this.show.type_of_inquiry_error = true
				} else {
					this.show.type_of_inquiry_error = false
				}
				return !this.show.type_of_inquiry_error
			},
			validateName() {
				if (!this.form.name.trim()) {
					this.show.name_error = true; // 如果 name 为空，显示错误
				} else {
					this.show.name_error = false; // 否则隐藏错误
				}
				return !this.show.name_error
			},
			validateEmail() {
				if (!this.form.email.trim()) {
					this.show.email_error = true
					this.show.email_error_msg = 'Required field'
				} else {
					const email = this.form.email.trim()
					const isValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
					this.show.email_error = !isValid
					this.show.email_error_msg = isValid ? 'Required field' : 'Invalid email format (e.g. your_email@domain.com)'
				}
				return !this.show.email_error
			},
			validateMsg() {
				this.form.msg = this.form.msg.slice(0, 500)
				if (!this.form.msg.trim()) {
					this.show.msg_error = true
				} else {
					this.show.msg_error = false
				}
				return !this.show.msg_error
			},
			onSubmit() {
				const isValid = [this.validateMsg(), this.validateTypeOfInquiryField(), this.validateName(), this.validateEmail()].every(value => value)
				if (isValid) {
					var body = {
						"msg_type": "interactive",
						"card": {
							"elements": [{
								"tag": "div",
								"text": {
									"content": "**" + this.form.name + "(" + this.form.email + ")" + "**",
									"tag": "lark_md"
								}
							}, {
								"tag": "div",
								"text": {
									"content": this.form.msg,
									"tag": "lark_md"
								}
							}],
							"header": {
								"title": {
									"content": this.form.type_of_inquiry,
									"tag": "plain_text"
								}
							}
						}
					}
					fetch("https://open.feishu.cn/open-apis/bot/v2/hook/9914ef24-213f-4c92-9405-2afbe087f29b", {
						"body": JSON.stringify(body),
						"method": "POST",
						"mode": "cors"
					}).then((response) => {
						if (response.status === 200) {
							this.submitted = true
						}
					}).catch(error => {
						console.log(error)
					})
				}
				
			}
		}
	}
</script>

<style>
	@media screen and (min-width: 62rem) {
		.contact__form {
			flex-basis: 100%;
		}
	}

	/* Submitted Form */
	.fsBody {
		-webkit-text-size-adjust: 100%;
		margin: 0;
		font-weight: 400;
		font-style: normal;
	}

	.fsBody {
		-webkit-text-size-adjust: 100%;
		margin: 0;
		font-weight: 400;
		font-style: normal;
	}

	.fsBody {
		padding: 20px;
	}

	.wf-active .fsBody,
	.wf-active .fsBody input,
	.wf-active .fsBody select,
	.wf-active .fsBody textarea {
		font-family: "Noto Sans", "Helvetica Neue", Arial, "Lucida Grande", Tahoma, Verdana, sans-serif;
	}

	.fsBody .fsForm {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}

	.fsBody .fsForm {
		float: center;
		border: 0 solid transparent;
		background-color: #f9f9f9;
		background-color: #f9f9f9;
	}

	.fsBody .fsForm {
		margin: 20px auto;
		padding: 20px;
	}

	.fsBody table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	.fsBody .fsForm .fsTable {
		text-align: left;
		padding: 0 0 4px;
		border: none;
		margin: 9px auto;
		width: 100%;
	}

	.fsBody .fsSectionHeader {
		background-color: transparent;
	}

	.fsBody .fsSectionHeader {
		padding: 20px;
	}

	#fsSubmissionCheckmark {
		float: left;
		width: 100%;
		height: 68px;
		background-size: 58px;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url(/img/confirmationCheckmark-new.png);
	}

	#fsSubmissionCheckmark~.fsSectionText {
		display: inline-block;
		width: 90%;
		margin-left: 5%;
		padding-left: 0 !important;
		text-align: center;
	}

	.fsForm_ {
		background-color: #091b29 !important;
		padding: 0 !important;
	}

	.fsBody_ {
		padding: 0 !important;
	}
</style>